@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  src: url(./fonts/avenir-next/avenir-next-regular.woff2) format('woff2'),
    url(./fonts/avenir-next/avenir-next-regular.woff) format('woff'),
    url(./fonts/avenir-next/avenir-next-regular.ttf) format('ttf');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: italic;
  src: url(./fonts/avenir-next/avenir-next-regular-italic.woff2) format('woff2'),
    url(./fonts/avenir-next/avenir-next-regular-italic.woff2) format('woff'),
    url(./fonts/avenir-next/avenir-next-regular-italic.woff2) format('ttf');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  src: url(./fonts/avenir-next/avenir-next-demi-bold.woff2) format('woff2'),
    url(./fonts/avenir-next/avenir-next-demi-bold.woff) format('woff'),
    url(./fonts/avenir-next/avenir-next-demi-bold.ttf) format('ttf');
}
